import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { Message, MessageService } from 'primeng/api';
import { FileUploadService } from './services/file-upload/file-upload.service';
import { SkyappJWTAuthService } from './services/skyapp-jwt-auth/skyapp-jwtauth.service';
import { CommonModule } from '@angular/common';
import { combineLatest, filter } from 'rxjs';
import { UtilService } from './services/util/util.service';
import { LoadingService } from './services/loading/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit  {
  title = 'skyapp-upload-ui';
  isUploadSuccess;
  isAccessTokenValid;
  isSkyAppTokenValid;
  isAPIFailed;
  isLoading;
  isAuthInitialized;
  messages!: Message[] ;
  
  isLogoutRoute = false;
  constructor( private loadingService: LoadingService,private utilService: UtilService,private messageService: MessageService, private skyappJwtAuthSerice: SkyappJWTAuthService, private router: Router, private uploadService: FileUploadService) {
    this.isUploadSuccess= false;
    this.isAccessTokenValid= false;
    this.isSkyAppTokenValid = false;
    this.isAPIFailed = false;
    this.isLoading=false;
    this.isAuthInitialized = false;
  }
  ngOnInit(): void {
    console.log("app component lauched");

    //this code is uesd to check the route. if the route is /logout then we need to still show the router outlet but not show the menubar.
    // That code is written in the app component html.
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event:any) => {      
      this.isLogoutRoute = event.url === '/logout';
    });


    
    combineLatest([
      this.utilService.getAPIErrorStatusSubject(),
      this.skyappJwtAuthSerice.getAccesTokenValidity(),
      this.skyappJwtAuthSerice.getSkyAppTokenValidity(),
      this.loadingService.loading$
    ]).subscribe(([APIFailStatus, isAccessTokenValid, isSkyAppTokenValid, isLoading]) => {
      this.isAPIFailed = APIFailStatus;
      this.isAccessTokenValid = isAccessTokenValid;
      this.isSkyAppTokenValid = isSkyAppTokenValid;
      this.isLoading = isLoading;

      // Set the boolean flag once the initial values have been emitted
      this.isAuthInitialized = true;  // This gets updated after all observables emit values
    });


    

    this.uploadService.isUploadSuccess.subscribe(uploadObj =>{
      if(uploadObj.isSuccess){
        this.messageService.add({ severity: 'success', summary: 'Success', detail: `File ${uploadObj.fileName} Upload Success ` });
      }
      else{
        this.messageService.add({ severity: 'error', summary: 'Error', detail: `File ${uploadObj.fileName} Upload failed ` });
      }

    });
    this.messages = [{  severity: 'success', summary: 'Success', detail: 'Upload Success' }];
  }

}
