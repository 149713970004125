<!-- <div *ngIf="(isAccessTokenValid && isSkyAppTokenValid) || isLogoutRoute">
  <div class="container">
    <app-menu *ngIf="!isLogoutRoute"></app-menu>
    <router-outlet></router-outlet>    
  </div>
  <div class="card">
    <p-toast></p-toast>
  </div>
</div>
<div>
  <app-spinner></app-spinner>
</div> -->


<div *ngIf="((isAccessTokenValid && isSkyAppTokenValid) || isLogoutRoute) ; else thenBlock">
  <div class="container">
    <app-menu *ngIf="!isLogoutRoute"></app-menu>
    <router-outlet></router-outlet>
  </div>
  <div class="card">
    <p-toast></p-toast>
  </div>
</div>
<ng-template #thenBlock>
  <div *ngIf="!isLoading && isAuthInitialized && isAccessTokenValid">
    <div *ngIf="isAPIFailed">
      <app-internal-error></app-internal-error>
    </div>
    <div *ngIf="!isAPIFailed && !isSkyAppTokenValid">
      <app-permission-denied-error></app-permission-denied-error>
    </div>
  </div>
</ng-template>
<div>
  <app-spinner></app-spinner>
</div>