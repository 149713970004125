<table class="upload-table">
  <thead>
    <th style="color: #000000; padding-left: 20px;">File Name</th>
    <th [ngStyle]="{ 'visibility': uploadType === 'FSPData' ? 'hidden' : 'visible' }" style="width: 30%; color: #000000;">Note</th>
    <th style="width: 30%; color: #000000;">Progress</th>
    <th
      [ngStyle]="{ 'visibility': (uploadType === 'FSPData' && flightLeg.rawDataUploaded === true) ? 'hidden' : 'visible' }"
      style="width: 10%; text-align:center; color: #000000; padding-right: 20px;">Actions</th>
  </thead>

  <tbody style="height: 50px;">
    <tr style="border: 1px solid #CFCFCF;" *ngFor="let eachFileDetail of multiFileDetails">
      <ng-container *ngIf="(eachFileDetail.state === 'Completed'); else elseBlock">
          <td style="padding-left: 20px;">{{eachFileDetail.fileName}}</td>
          <td [ngStyle]="{ 'visibility': eachFileDetail.uploadType !== 'ArbitraryData' ? 'hidden' : 'visible' }">{{eachFileDetail.notes !== '' && eachFileDetail.notes !== undefined ? eachFileDetail.notes : '---'}}</td>
          <td>
            <div style="display: flex; flex-direction: column; justify-content: left;">
              <div style="color: green; font-weight: bold;">Uploaded</div>
            </div>
          </td>
          <td *ngIf="uploadType === 'ArbitraryData'" style="padding-right: 20px;">
            <img class="m-2" style="height: 1.5em; width: 1.5em; cursor: pointer;"
              pTooltip="Arbitrary data can be downloaded from flight leg detail screen." tooltipPosition="bottom"
              src="../../../../../assets/boeing/eye-icon.svg" />
          </td>
      </ng-container>
      <ng-template #elseBlock>
        <td style="padding-left: 20px;">{{eachFileDetail.fileName}}</td>
        <td [ngStyle]="{ 'visibility': eachFileDetail.uploadType !== 'ArbitraryData' ? 'hidden' : 'visible' }"><textarea
            matInput placeholder="Add your note here...." [(ngModel)]="eachFileDetail.notes" rows="2" cols="40"
            [disabled]="eachFileDetail.state === 'In Progress' || eachFileDetail.state === 'Completed'">{{eachFileDetail.notes}}</textarea>
        </td>
        <td>
          <div style="display: flex; flex-direction: column; justify-content: left;">
            <div>{{eachFileDetail.state !='Cancelled' ? eachFileDetail.state:'' }}</div>
            <div *ngIf="eachFileDetail.state==='In Progress' || eachFileDetail.state=== 'Completed'" [ngStyle]="{'color': eachFileDetail.state=='Completed' ? '#37A510' : '#DE9200' }" class="upload-progress">
              <mat-progress-bar mode="determinate"
                [ngStyle]="{'border-color': eachFileDetail.state=='Completed' ? '#37A510' : '#DE9200' }"
                value="{{eachFileDetail.progress}}"></mat-progress-bar>
                <div *ngIf="eachFileDetail.state==='In Progress' || eachFileDetail.state=== 'Completed'"
            [ngStyle]="{'color': eachFileDetail.state=='Completed' ? '#37A510' : '#DE9200' }">
            {{eachFileDetail.progress}} %</div>
            </div>
            
          </div>
        </td>
      </ng-template>

      <td style="padding-left: 5px;">
        <mat-icon class="ml-2" (click)="cancelUpload(eachFileDetail)"
          *ngIf="eachFileDetail.state==='In Progress'">cancel</mat-icon>
        <mat-icon class="ml-2" (click)="resetStage(eachFileDetail)"
          *ngIf="eachFileDetail.state==='Failed'|| eachFileDetail.state==='Cancelled' || eachFileDetail.state==='' ">delete</mat-icon>
        <mat-icon class="ml-2" (click)="uploadFile('Arbitrary-Upload')"
          *ngIf="eachFileDetail.state==='Failed' || eachFileDetail.state==='Cancelled'">refresh</mat-icon>
      </td>
    </tr>
  </tbody>
</table>