import { AuthConfig } from "angular-oauth2-oidc";
import { environment } from '../environments/environment';

export const wssoAuthCodeFlowConfig: AuthConfig = {
  issuer: environment.wssoIssuer,
  redirectUri: window.location.origin + '/home',
  clientId: environment.wssoClientId,
  responseType: 'code',
  scope: `openid profile ${environment.baseUrl}/read`,
  customQueryParams: { "idp": environment.wssoIDP },
  useSilentRefresh: true,
  silentRefreshRedirectUri: window.location.origin + "/silent-refresh.html",
};

// export const aviatioIdAuthCodeFlowConfig: AuthConfig = {
//   issuer: 'https://auth.test.fedb.digitalaviationservices.com',
//   redirectUri: window.location.origin + '/login?idp=aviationid',
//   clientId: 'skyapp_dev',
//   responseType: 'code',
//   scope: 'openid profile https://dev.sky.digitalaviationservices.com/read',
//   customQueryParams: { "idp": "urn:aviationid:uat:fedproxy:daf-jit-v2:np" },
//   useSilentRefresh: true,
//   silentRefreshRedirectUri: window.location.origin + "/silent-refresh.html",
// };

