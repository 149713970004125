import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { DataSourceSyncStatus } from 'src/app/models/data-source-syncstatus-model-interface';
import { map } from 'rxjs/operators';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { FlightLeg } from 'src/app/models/flight-leg-interface';
import { SkyappJWTAuthService } from 'src/app/services/skyapp-jwt-auth/skyapp-jwtauth.service';
import { FolderDownloadService } from 'src/app/services/file-download/folder-download.service';
import { FileDownloadContainer } from 'src/app/models/file-download-container-interface';
import { environment } from 'src/environments/environment';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { UtilService } from 'src/app/services/util/util.service';
import { FlightLegsService } from 'src/app/services/flight-legs/flight-legs.service';
import { TimeFormatService } from 'src/app/services/timeformat/time-format.service';
import { ArbitaryData } from 'src/app/models/arbitary-data-interface';

@Component({
  selector: 'app-details-dialog',
  templateUrl: './details-dialog.component.html',
  styleUrls: ['./details-dialog.component.scss'],
})
export class DetailsDialogComponent implements OnInit {
  marshallingDataSet: DataSourceSyncStatus[] = [];
  selectedFlightLeg!: FlightLeg;
  arbitraryData: ArbitaryData[] = [];
  gridColumnSize: number = 6;
  legGuidColSize: number = 2;
  isAdminOrAnalyst: Boolean | undefined;
  timeFormatTooltip: string = ''

  isLoading = true;
  isAPIFailed = false;
  customOrder = ['ADSB', 'FSP', 'EXTERNAL', 'MARSHALLING']; // sorting By this Order
  status: string = '';
  isDownloading_Processed_FSPData: boolean = false;
  isDownloading_ADSB_Data: boolean = false;
  isDownloading_Finalized_FSPData: boolean = false;
  isDownloading_External_Data: boolean = false;
  isADSBFetchAPIInProgress = false;
  isFinalizedFSPDataFetchAPIInProgress = false;
  fileDownloadStatusMap: { [key: string]: FileDownloadContainer } = {};

  // A map to track which files are currently being downloaded
  downloadingFilesMap: { [fileName: string]: boolean } = {};

  constructor(
    private breakpointObserver: BreakpointObserver,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private skyAppJwtAuthService: SkyappJWTAuthService,
    private fileDownloadService: FolderDownloadService,
    private flightLegsService: FlightLegsService,
    private utilService: UtilService,
    private cdr: ChangeDetectorRef,
    private timeFormatService: TimeFormatService,
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<DetailsDialogComponent>,
  ) { }

  ngOnInit(): void {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(
        map((result) => {
          if (
            result.breakpoints[Breakpoints.Large] ||
            result.breakpoints[Breakpoints.XLarge]
          ) {
            this.legGuidColSize = 2;
            return 6; // 2:1:1:1:1 ratio for laptop screens
          } else {
            this.legGuidColSize = 1;
            return 1; // 1:1:1:1:1 ratio for mobile screens
          }
        })
      )
      .subscribe((cols) => (this.gridColumnSize = cols));

    this.status = 'Download flight leg data';

    this.selectedFlightLeg = this.data.flight;
    //Getting the arbitrary data from @Inject(MAT_DIALOG_DATA) public data: any;
    this.arbitraryData = this.data.arbitrary;
    if (this.arbitraryData == undefined) {
      this.arbitraryData = [];
    }

    //This is to sort the data from the API to a particular order so that the values in the array can be placed in a particular sequence
    if (this.selectedFlightLeg != undefined) {
      this.marshallingDataSet =
        this.selectedFlightLeg.dataSourceSyncStatuses.sort((a, b) => {
          return (
            this.customOrder.indexOf(a.dataSourceType) -
            this.customOrder.indexOf(b.dataSourceType)
          );
        });
    }


    this.isAdminOrAnalyst = this.skyAppJwtAuthService.isAdminOrAnalyst();

    //Calling this function for capturing the progress
    this.getFileStatusAndProgress();
    this.utilService.getAPIErrorStatusSubject().subscribe(APIFailStatus => {
      this.isAPIFailed = APIFailStatus;
    })

    this.timeFormatService.currentFormat$.subscribe((timeformat) => {
      if (timeformat === 'local') {
        this.timeFormatTooltip = 'Showing Airport Local Time\n(change time zone from App header)';
      } else {
        this.timeFormatTooltip = 'Showing UTC Time\n(change time zone from App header)'
      }
    })
  }

  closeButtonClick(): void {
    this.dialogRef.close();
    this.utilService.setAPIErrorStatusSubject(false);
  }

  triggerADSBFetch(selectedFlightLeg: FlightLeg) {
    this.marshallingDataSet[0].message = null;
    this.isADSBFetchAPIInProgress = true;
    this.marshallingDataSet[0].dataSourceStatus = "In Progress";
    this.flightLegsService.triggerADSBFetch(selectedFlightLeg.flightLegId).subscribe((response) => {
      this.isADSBFetchAPIInProgress = false;
      if (response.code === 'DATA_NOT_AVAILABLE') {
        this.marshallingDataSet[0].dataSourceStatus = "Unavailable";
      }
      this.marshallingDataSet[0].message = response.message;

    }, (error) => {
      this.marshallingDataSet[0].dataSourceStatus = "Error Occured";
      this.marshallingDataSet[0].message = error.message;
    })

  }

  triggerFinalizedFSPDataFetch(selectedFlightLeg: FlightLeg) {
    this.marshallingDataSet[1].message = null;
    this.isFinalizedFSPDataFetchAPIInProgress = true;
    this.marshallingDataSet[1].dataSourceStatus = "In Progress";
    this.flightLegsService.triggerFinalizedFSPDataFetch(selectedFlightLeg.flightLegId).subscribe({
      next: (response) => {
        this.isFinalizedFSPDataFetchAPIInProgress = false;
        if (response.code === 'DATA_NOT_AVAILABLE') {
          this.marshallingDataSet[1].dataSourceStatus = "Unavailable";
        }
        this.marshallingDataSet[1].message = response.message;
      },
      error: (error) => {
        this.marshallingDataSet[1].dataSourceStatus = "Error Occurred";
        this.marshallingDataSet[1].message = error.message;
      },
      complete: () => {
        console.log('Finalized FSP Data Fetch completed');
      }
    });
  }

  async downloadContent(downloadContentType: string, documentName: string): Promise<void> {
    let fileName = '';
    let containerName = '';
    let path = '';

    switch (downloadContentType) {
      case 'Pre-Processed_FSPData': {
        this.status = 'Downloading ...';
        this.isDownloading_Processed_FSPData = true;
        fileName = `FSP_Pre-processed_${this.selectedFlightLeg.legGUID}.zip`;
        containerName = environment.extractedDataContainername
        path = `${this.selectedFlightLeg.bemsId}/${this.selectedFlightLeg.legGUID}/fsp-data/`;
        break;
      }
      case 'Finalized_FSPData': {
        fileName = `FSP_Finalized_${this.selectedFlightLeg.legGUID}.zip`;
        containerName = environment.processedDataContainerName
        path = `${this.selectedFlightLeg.processedFilesBasePath}Finalized_FSP_${this.selectedFlightLeg.legGUID}/`;
        break;
      }
      case 'ADSB_Data': {
        fileName = `ADSB-Mode-S-${this.selectedFlightLeg.fr24FlightId}_Finalized_${this.selectedFlightLeg.legGUID}.csv`;
        containerName = environment.processedDataContainerName
        path = `${this.selectedFlightLeg.processedFilesBasePath}${fileName}`;
        break;
      }
      case 'Arbitrary_Data': {
        fileName = documentName;
        containerName = environment.extractedDataContainername
        path = `${this.selectedFlightLeg.bemsId}/${this.selectedFlightLeg.legGUID}/arbitrary-data/${documentName}`;
        break;
      }
      case 'External_Data': {
        fileName = `External_Finalized_${this.selectedFlightLeg.legGUID}.zip`;
        //todo
        break;
      }
      case 'Additional_Data': {
        fileName = `Additional_${this.selectedFlightLeg.legGUID}.zip`;
        //todo
        break;
      }
      case 'Marshalled_Data': {
        fileName = `Processed_${this.selectedFlightLeg.legGUID}.zip`;
        //todo
        break;
      }
      default: {
        throw new Error('downloadContentType not matched');
      }
    }

    let identifier = `${this.selectedFlightLeg.legGUID}-DOWNLOAD-${downloadContentType}-${fileName}`;

    this.fileDownloadService.downloadContent(fileName, path, identifier, containerName)
  }

  getFileStatusAndProgress() {
    this.fileDownloadService.getFileProgressUpdates().subscribe((status) => {

      Object.entries(status).forEach(([key, value]) => {

        if (key.startsWith(`${this.selectedFlightLeg.legGUID}-DOWNLOAD-Pre-Processed_FSPData`)) {
          this.fileDownloadStatusMap['Pre-Processed_FSPData'] = value;
          if (value.loadedBytes < value.totalBytes) {
            this.isDownloading_Processed_FSPData = true;
            this.status = 'Downloading ...';
          }
          else {
            this.isDownloading_Processed_FSPData = false;
            this.status = 'Download flight leg data';
          }
        }
        else if (key.startsWith(`${this.selectedFlightLeg.legGUID}-DOWNLOAD-Finalized_FSPData`)) {
          this.fileDownloadStatusMap['Finalized_FSPData'] = value;
          if (value.loadedBytes < value.totalBytes) {
            this.isDownloading_Finalized_FSPData = true;
            console.log('isDownloading_Finalized_FSPData', this.isDownloading_Finalized_FSPData)
          }
          else {
            this.isDownloading_Finalized_FSPData = false;
          }
        }
        else if (key.startsWith(`${this.selectedFlightLeg.legGUID}-DOWNLOAD-ADSB_Data`)) {
          this.fileDownloadStatusMap['ADSB_Data'] = value;
          if (value.loadedBytes < value.totalBytes) {
            this.isDownloading_ADSB_Data = true;
            console.log('isADSBDownloading', this.isDownloading_ADSB_Data)
          }
          else {
            this.isDownloading_ADSB_Data = false;
          }
        }
        else if (key.startsWith(`${this.selectedFlightLeg.legGUID}-DOWNLOAD-Arbitrary_Data`)) {

          this.fileDownloadStatusMap[value.name] = value;

          if (value.loadedBytes < value.totalBytes) {
            this.downloadingFilesMap[value.name] = true;
          }
          else {
            this.downloadingFilesMap[value.name] = false;
          }
        }
        else if (key.startsWith(`${this.selectedFlightLeg.legGUID}-DOWNLOAD-External_Data`)) {
          this.fileDownloadStatusMap['External_Data'] = value;
          if (value.loadedBytes < value.totalBytes) {
            this.isDownloading_External_Data = true;
            console.log('isDownloading_External_Data', this.isDownloading_External_Data)
          }
          else {
            this.isDownloading_External_Data = false;
          }
        }
        else if (key.startsWith(`${this.selectedFlightLeg.legGUID}-DOWNLOAD-Marshalled_Data`)) {
          this.fileDownloadStatusMap['Marshalled_Data'] = value;
        }
        else if (key.startsWith(`${this.selectedFlightLeg.legGUID}-DOWNLOAD-Additional_Data`)) {
          this.fileDownloadStatusMap[`Additional_Data-${value.name}`] = value;
        }

      });

      this.cdr.detectChanges();

    });
  }

  // Function to extract the file name from the key using regex
  extractFileNameFromKey(key: string): string {
    // Regex to match the file name with extension at the end of the string
    const match = key.match(/[^_]+(\.[a-zA-Z0-9]+)$/);
    return match ? match[0] : ''; // Return the matched file name or an empty string if no match
  }
}
