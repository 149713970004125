import { Component, OnInit } from '@angular/core';
import { SkyappJWTAuthService } from 'src/app/services/skyapp-jwt-auth/skyapp-jwtauth.service';

@Component({
  selector: 'app-permission-denied-error',
  templateUrl: './permission-denied-error.component.html',
  styleUrls: ['./permission-denied-error.component.scss']
})
export class PermissionDeniedErrorComponent  implements OnInit{
  constructor(private skyappJwtAuthSerice: SkyappJWTAuthService){
    
  }
  ngOnInit(): void {
   
  }

}
