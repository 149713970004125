import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Dialog } from 'primeng/dialog';
import { FlightLeg } from 'src/app/models/flight-leg-interface';
import { FlightLegListComponent } from 'src/app/modules/home/components/flight-leg-list/flight-leg-list.component';
import { UploadFlightLegDialogComponent } from 'src/app/modules/home/components/upload-flight-leg-dialog/upload-flight-leg-dialog.component';
import { FileUploadService } from 'src/app/services/file-upload/file-upload.service';
import { FlightLegsService } from 'src/app/services/flight-legs/flight-legs.service';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {
  constructor(
    public dialogRef: MatDialogRef<NotificationDialogComponent>,    
    private fileUploadService: FileUploadService,
    @Inject(MAT_DIALOG_DATA) public data: {status:boolean; flightleg: FlightLeg,dialog:MatDialogRef<UploadFlightLegDialogComponent> }
  ) {}

  ngOnInit(): void {
    if(this.data.status){
      setTimeout(() => {
        this.dialogRef.close();
      }, 2000);// 2 sec auto close for success dialog
    }
  }
  onNoClick(): void {
    this.dialogRef.close();
  }
  Reopen():void{
    this.data.dialog.close();
    this.fileUploadService.showUploadDialog(this.data.flightleg);
    this.onNoClick();
  }
}
