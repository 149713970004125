import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ADSBResponse } from 'src/app/models/adsb-response';
import { ArbitaryData } from 'src/app/models/arbitary-data-interface';
import { Finalized_FSPData_Response } from 'src/app/models/finalized-fsp-data-response';
import { FlightLeg } from 'src/app/models/flight-leg-interface';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class FlightLegsService {

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };


  constructor(private http: HttpClient) {}


  getFlightLegs(): Observable<FlightLeg[]> {
    return this.http.get<FlightLeg[]>(`${environment.baseUrl}/api/flightLeg/myFlightLegs`);
  }

  getArbitraryData(flightLegId: number): Observable<ArbitaryData[]> {
    return this.http.get<ArbitaryData[]>(`${environment.baseUrl}/api/arbitrary-data/flightLeg/${flightLegId}`);
  }


  updateFlightLeg(flightLeg: FlightLeg): Observable<FlightLeg> {
      return this.http.put<FlightLeg>(`${environment.baseUrl}/api/flightLeg/${flightLeg.flightLegId}`, flightLeg, this.httpOptions);
  }

  async saveMetaData(saveMetaData: ArbitaryData): Promise<Observable<ArbitaryData>> {
    return this.http.post<ArbitaryData>(`${environment.baseUrl}/api/arbitrary-data`, saveMetaData, this.httpOptions);
}

   getFlightLegDetails(id: number): Observable<FlightLeg> {
     return this.http.get<FlightLeg>(`${environment.baseUrl}/api/flightLeg/${id}`);
   }

   triggerADSBFetch(id: number): Observable<ADSBResponse> {
    return this.http.get<ADSBResponse>(`${environment.baseUrl}/api/flightLeg/${id}/triggerADSBFetch`);
  }

  triggerFinalizedFSPDataFetch(id: number): Observable<Finalized_FSPData_Response> {
    return this.http.get<Finalized_FSPData_Response>(`${environment.baseUrl}/api/flightLeg/${id}/triggerFinalizedFSPDataFetch`);
  }
}
