import { ChangeDetectorRef, Component, Inject, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { FlightLeg } from 'src/app/models/flight-leg-interface';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FileUploadService } from 'src/app/services/file-upload/file-upload.service';
import { UploadComponent } from 'src/app/shared/components/upload/upload.component';
import { FileUploadContainer } from 'src/app/models/upload-file-interface';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-upload-flight-leg-dialog',
  templateUrl: './upload-flight-leg-dialog.component.html',
  styleUrls: ['./upload-flight-leg-dialog.component.scss'],
})
export class UploadFlightLegDialogComponent implements OnInit {
  @ViewChild(UploadComponent) uploadComponent!: UploadComponent;

  filesSelected: File[] = [];
  disableButton: boolean = true;

  arbitraryDataFileArray: FileUploadContainer[] = [];
  fspDataFileArray: FileUploadContainer[] = [];

  selectedFlightLeg!: FlightLeg;
  uploadStatus!: FileUploadContainer[];
  isUploading: boolean = false;

  items: MenuItem[] = [];

  activeItem: MenuItem = {};
  multiple!: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadFlightLegDialogComponent>,
    public uploadService: FileUploadService
  ) { }

  ngOnInit(): void {
    this.selectedFlightLeg = this.data.flight;
    if(this.selectedFlightLeg.rawDataUploaded === true){
      const fileDetail: FileUploadContainer = {
        file: null,
        fileName: `${this.selectedFlightLeg.legGUID}.zip`,
        fileType: `zip`,
        progress: 100,
        state: 'Completed',
        cancelTokenSource: new AbortController,
        notes: '',
        identifier: `${this.selectedFlightLeg.legGUID}-UPLOAD-RawSensorData-${this.selectedFlightLeg.legGUID}.zip`,
        uploadType: 'RawSensorData'
      };
      this.fspDataFileArray.push(fileDetail);
    }
    this.arbitraryDataFileArray = this.data.arbitraryData;

    // When the dialog is closed and reopened, any ongoing uploads should continue to display their progress
    this.uploadStatus = this.uploadService.getUploadStatusByMatchingPrefix(`${this.selectedFlightLeg.legGUID}-UPLOAD-`);
    //If length>0 then there is a file upload in progress or failed and ready to retry
    if (this.uploadStatus.length > 0) {
      this.disableButton = true;
      this.uploadStatus.map((currentFile: FileUploadContainer) => {
        if (currentFile.uploadType === 'RawSensorData' && currentFile.state === 'In Progress') {
          this.fspDataFileArray.push(currentFile);
        } else if (currentFile.uploadType === 'ArbitraryData' && currentFile.state === 'In Progress') {
          this.arbitraryDataFileArray.push(currentFile);
        }
      });
    }

    this.items = [
      { label: 'Upload FSP Data' },
      { label: 'Upload Arbitrary Data' }
    ];

    this.activeItem = this.items[0];
    this.multiple = true;
  }

  onActiveItemChange(event: MenuItem) {
    console.log('Active item changed:', event);
    this.activeItem = event;
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

  fileBrowseHandler($event: any, type: string) {
    this.filesSelected = $event.target.files;
    console.log(this.filesSelected);
    if (this.filesSelected.length > 0) {
      for (let i = 0; i < this.filesSelected.length; i++) {
        
        const uploadType = (type === 'FSP-Upload') ? 'RawSensorData' : 'ArbitraryData';
        let identifier = `${this.selectedFlightLeg.legGUID}-UPLOAD-${uploadType}-${this.filesSelected[i].name}`;
        // Upload each file here
        const fileDetail: FileUploadContainer = {
          file: this.filesSelected[i],
          fileName: this.filesSelected[i].name,
          fileType: this.filesSelected[i].name.split('.').pop(),
          progress: 0,
          state: '',
          cancelTokenSource: new AbortController,
          notes: '',
          identifier: identifier,
          uploadType: uploadType
        };
        if (type == 'FSP-Upload') {
          this.fspDataFileArray.push(fileDetail);
        } else {
          // Check if fileName is exist or not
          const fileExists = this.arbitraryDataFileArray.some(file => file.fileName === fileDetail.fileName);
          if (fileExists) {
            alert(`File "${fileDetail.fileName}" already exists in the arbitrary data.`);
          } else {
            this.arbitraryDataFileArray.push(fileDetail);
          }
        }
      }
      this.disableButton = false;
    }
  }

  async startupload(type: string) {
    this.isUploading = true; // The 'Add more files' button should be disabled while the upload process is in progress.
    this.uploadComponent.uploadFile(type);
    this.disableButton = true;
    // The 'Add more files' button should be enabled while the upload process is completed.
    this.uploadService.uploadLegStatusSubjectForDisable.subscribe(() => {
      console.log("subject has been called");
      this.isUploading = false;
    });
  }

  // This will be triggered when the selected file is removed using the rest icon. We are using @Output and event emitter for this functionality
  onfileChange(updatedFiles: FileUploadContainer) {
    if (updatedFiles.uploadType === 'RawSensorData') {
      this.fspDataFileArray = [];
    } else {
      this.arbitraryDataFileArray = this.arbitraryDataFileArray.filter(file => file !== updatedFiles);
    }
  }
}
