import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { MenuComponent } from './components/menu/menu.component';
import { MenubarModule } from 'primeng/menubar';
import { InputTextModule } from 'primeng/inputtext';
import { UploadComponent } from './components/upload/upload.component';
import { MatCardModule } from '@angular/material/card';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatListModule } from '@angular/material/list';
import { HttpClientModule } from '@angular/common/http';
import { NoopAnimationsModule } from '@angular/platform-browser/animations';
import { MatIconModule } from '@angular/material/icon';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { DynamicDialogModule } from 'primeng/dynamicdialog';
import { ButtonModule } from 'primeng/button';
import { MenuModule } from 'primeng/menu';
import { NotificationDialogComponent } from './components/notification-dialog/notification-dialog.component';
import { DialogModule } from 'primeng/dialog';
import { LocalDateTimePipe } from './pipes/local-date-time-pipe';
import { TimeFormatPipe } from './pipes/time-format.pipe';




@NgModule({
  declarations: [
    SpinnerComponent,
    MenuComponent,
    UploadComponent,
    NotificationDialogComponent,
    LocalDateTimePipe,
    TimeFormatPipe
  ],
  imports: [
    CommonModule,
    MenubarModule,
    InputTextModule,
    MatCardModule,
    MatToolbarModule,
    MatButtonModule,
    MatInputModule,
    MatFormFieldModule,
    MatProgressBarModule,
    MatListModule,
    HttpClientModule,
    NoopAnimationsModule,
    MatIconModule,
    BrowserModule,
    FormsModule,
    DynamicDialogModule,
    MenuModule,
    ButtonModule,
    DialogModule
  ],
  exports:[
    SpinnerComponent,
    MenuComponent,
    UploadComponent,
    LocalDateTimePipe,
    TimeFormatPipe
  ]
})
export class SharedModule { }
