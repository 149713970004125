import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { Table } from 'primeng/table';
import { FlightLeg } from 'src/app/models/flight-leg-interface';
import { FlightLegsService } from 'src/app/services/flight-legs/flight-legs.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { map } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import { DetailsDialogComponent } from '../details-dialog/details-dialog.component';
import { FileUploadService } from 'src/app/services/file-upload/file-upload.service';
import { FileUploadContainer } from 'src/app/models/upload-file-interface';
import { SortEvent } from 'primeng/api';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { UtilService } from 'src/app/services/util/util.service';
import { TimeFormatService } from 'src/app/services/timeformat/time-format.service';
import { ArbitaryData } from 'src/app/models/arbitary-data-interface';
import { forkJoin } from 'rxjs';
@Component({
  selector: 'app-flight-leg-list',
  templateUrl: './flight-leg-list.component.html',
  styleUrls: ['./flight-leg-list.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class FlightLegListComponent implements OnInit {
  flightLegs: FlightLeg[] = [];
  flightLegDetails: { [id: number]: FlightLeg } = {};
  displayflightLegs: FlightLeg[] = [];
  arbitraryData: ArbitaryData[] = [];

  activeSortField: string | undefined;
  date: Date = new Date();
  displayedColumns: string[] = [
    'legGUID',
    'flightId',
    'flightNumber',
    'bemsId',
    'departureAirportCode',
    'departureDate',
    'arrivalAirportCode',
    'arrivalDate',
    'flightLegStatus',
    'symbol',
  ];
  // uploadStatuses: { [key: string]: FileUploadContainer } = {};
  statuses: { [key: string]: string } = {};

  first: number = 0;
  rows: number = 10; // Number of rows to display per page
  cols: number = 6;
  bigCol: number = 2;

  search = '';
  isLoading=false;
  isAPIFailed=false;
  timeFormatTooltip:string =''
  options: any[] = [
    { label: '10', value: 10 },
    { label: '25', value: 25 },
    { label: '50', value: 50 },
  ];
  totalRecords = 0;

  @ViewChild('dt') dt!: Table;

  constructor(
    private flightService: FlightLegsService,
    private breakpointObserver: BreakpointObserver,
    private dialog: MatDialog,
    private uploadService: FileUploadService,
    private loadingService: LoadingService,
    private utilService: UtilService,
    private timeFormatService: TimeFormatService,
    private cdr: ChangeDetectorRef
  ) {
  }



  ngOnInit(): void {
    this.breakpointObserver
      .observe([
        Breakpoints.XSmall,
        Breakpoints.Small,
        Breakpoints.Medium,
        Breakpoints.Large,
        Breakpoints.XLarge,
      ])
      .pipe(
        map((result) => {
          if (
            result.breakpoints[Breakpoints.Large] ||
            result.breakpoints[Breakpoints.XLarge]
          ) {
            this.bigCol = 2;
            return 5; // 2:1:1:1:1 ratio for laptop screens
          } else {
            this.bigCol = 1;
            return 1; // 1:1:1:1:1 ratio for mobile screens
          }
        })
      )
      .subscribe((cols) => (this.cols = cols));

    this.getFlightLegs();

        console.log("On Init of flight Leg list called");
        
    ;
    

    this.uploadService.getLegStatusUpdates().subscribe((uploadStatusMap) => {
      this.computeStatuses(uploadStatusMap);
    });

    this.loadingService.loading$.subscribe( (isLoading) =>{
      this.isLoading = isLoading;
    })

    this.utilService.getAPIErrorStatusSubject().subscribe((APIFailStatus)=>{    
      this.isAPIFailed = APIFailStatus;
    })
    this.timeFormatService.currentFormat$.subscribe((timeformat) =>{
      if(timeformat==='local'){
       this.timeFormatTooltip = 'Airport Local Time';
      }else{
       this.timeFormatTooltip = 'UTC Time'
      }
     })

     
    
  }

  customSort(event: SortEvent) {
    if (event.field) {
      this.activeSortField = event.field;
      // Implement your sorting logic here
      this.displayflightLegs.sort((data1, data2) => {
        if (event.order) {
          const value1 = data1[event.field as keyof FlightLeg];
          const value2 = data2[event.field as keyof FlightLeg];
          let result = 0;
          if (value1 == null && value2 != null) {
            result = -1;
          } else if (value1 != null && value2 == null) {
            result = 1;
          } else if (value1 == null && value2 == null) {
            result = 0;
          } else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
          } else {
            if (value1 && value2) {
              result = value1 < value2 ? -1 : value1 > value2 ? 1 : 0;
            }
          }
          return event.order * result;
        } else {
          return 0;
        }
      });
    }
  }

  //Triggered when the page is changed in the table
  onPageChange(event: any) {
    this.first = event.first;
    this.rows = event.rows;
    this.displayflightLegs = this.flightLegs.slice(
      this.first,
      this.first + this.rows
    );
  }
  // triggered when the no: of rows per page selection changes
  itemChange(event: any) {
    console.log('item change' + event);
    this.displayflightLegs = this.flightLegs.slice(
      this.first,
      this.first + event.value
    );
  }

  // The search filter functionality
  applyFilterGlobal($event: any, stringVal: any) {
    console.log(($event.target as HTMLInputElement).value);
    this.dt!.filterGlobal(
      ($event.target as HTMLInputElement).value,
      'contains'
    );
  }

  fileBrowseHandler($event: any) {
    console.log($event.target.files);
  }

  async showUploadDialog($event: Event, flightLeg: FlightLeg) {
    $event.stopPropagation();
    this.uploadService.showUploadDialog(flightLeg);
    this.flightLegDetails = {};
  }

  getFlightLegs(): void {
    console.log("getFlightLegs called");
    
    this.flightService.getFlightLegs().subscribe((flightLegs) => {
      console.log("flightLegs:", flightLegs);
      
      if (flightLegs.length > 0) {
        this.flightLegs = flightLegs;
        this.totalRecords = this.flightLegs.length;
        this.displayflightLegs = this.flightLegs.slice(
          this.first,
          this.first + this.rows
        );
        console.log("displayflightLegs",this.displayflightLegs)
      }
    });
  }

  async loadFlightDetails(flightLeg: any): Promise<void> {
      try {
        const flightDetails = this.flightService.getFlightLegDetails(flightLeg.flightLegId);
        const arbitraryData = this.flightService.getArbitraryData(flightLeg.flightLegId);
        // Combine both calls
        forkJoin([flightDetails, arbitraryData]).subscribe(
          ([flightDetails, arbitraryData]) => {
            if (flightDetails) {
              this.flightLegDetails[flightLeg.flightLegId] = flightDetails;
            } else {
              this.flightLegDetails[flightLeg.flightLegId] = { ...flightLeg }; // Fallback if no detail found
            }
            if (arbitraryData) {
              this.arbitraryData = arbitraryData;
            }

            this.openDetailsDialog(flightLeg.flightLegId);
          },
          (error) => {
            console.error('Error loading flight details or arbitrary data', error);
            this.flightLegDetails[flightLeg.flightLegId] = { ...flightLeg };
            this.openDetailsDialog(flightLeg.flightLegId);
          }
        );
      } catch (error) {
        console.error('Error loading flight details', error);
        this.flightLegDetails[flightLeg.flightLegId] = { ...flightLeg };
        this.openDetailsDialog(flightLeg.flightLegId);
      }
  }

  openDetailsDialog(flightLegId: number): void {
    this.dialog.open(DetailsDialogComponent, {
      width: '95vw',
      height: '90vh',
      maxWidth: '95vw',
      minWidth: '95vw',
      data: {
        flight: this.flightLegDetails[flightLegId],
        arbitrary: this.arbitraryData,
      },
    });
  }

  // getUploadStatus(keyString: string): string {
  //   console.log('getUploadStatus called');
  //   const entry = Object.entries(this.uploadStatuses).filter(([key, value]) =>
  //     key.startsWith(keyString)
  //   );
  //   console.log(entry);
  //   if (entry.length > 0) {
  //     if (entry.some(([key, val]) => val.state == 'Failed')) {
  //       return 'Failed';
  //     } else if (entry.some(([key, val]) => val.state == 'In Progress')) {
  //       return 'In Progress';
  //     } else if (entry.some(([key, val]) => val.state == 'Cancelled')) {
  //       return 'Cancelled';
  //     } else {
  //       return '';
  //     }
  //   } else {
  //     return '';
  //   }
  // }

  computeStatuses(uploadStatusMap: { [key: string]: FileUploadContainer }) {
    console.log('Compute Statuses called');
    const prefixMap: { [key: string]: string } = {};
    Object.entries(uploadStatusMap).forEach(([key, value]) => {
      const prefix = this.extractPrefix(key);
      if (prefix !== '') {
        if (!prefixMap[prefix]) {
          prefixMap[prefix] = value.state;
        } else if (value.state === 'Failed') {
          prefixMap[prefix] = 'Failed';
        } else if (
          prefixMap[prefix] !== 'Failed' &&
          value.state === 'In Progress'
        ) {
          prefixMap[prefix] = 'In Progress';
        } else if (
          prefixMap[prefix] !== 'Failed' &&
          prefixMap[prefix] !== 'In Progress' &&
          value.state === 'Cancelled'
        ) {
          prefixMap[prefix] = 'Cancelled';
        }
      }
    });
    this.statuses = prefixMap;


  }

  extractPrefix(input: string): string {
    const match = input.match(/^(.+?-UPLOAD)/);
    return match ? match[1] : '';
  }


}
