import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'localDateTimePipe'
})
export class LocalDateTimePipe implements PipeTransform {

  transform(value: string): string {
    if (!value) {
      return '';
    }

    // Parse the UTC string to Luxon DateTime object in UTC timezone
    const dateTimeUtc = DateTime.fromISO(value, { zone: 'utc' });

    // Convert UTC datetime to local datetime
    const dateTimeLocal = dateTimeUtc.toLocal();

    // Format the local datetime in 'yyyy-MM-dd HH:mm:ss'
    const formattedDate = dateTimeLocal.toFormat('MM/dd/yyyy HH:mm:ss');

    return formattedDate;
  }

}
