<!-- <div class="login-container">
    <div class="login-component">
        <form>
            <fieldset class="boeing-fieldset">
        
                <legend class="boeing-legend">SkyApp Login Form</legend>
        
                <div class="boeing-margin">
                    <input class="boeing-input" type="text" placeholder="BEMSID"  [(ngModel)]="bemsId" name="bemsId">
                </div>
        
             
                <button class="boeing-button boeing-button-primary" type="button" (click)="login()">Login</button>
            </fieldset>
        </form>
    </div>
    
</div> -->

<div class="login-container grid" *ngIf="!isRedirected">
    <div class="logo-container flex flex-column  justify-content-center col-12  md:col-12 lg:col-8 xl:col-8">
        <div class="img-container">
            <img src="../../../../../assets/boeing/Boeing_PMSblue_large.svg" alt="boeing" />
        </div>
        <div class="flex  flex-column" style="color:#0033A1;">
            <h2>Welcome to SkyApp</h2>
            <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</h4>
        </div>
    </div>
    <div class="button-container flex flex-column align-items-center justify-content-center col-12 md:col-12 lg:col-4 xl:col-4">
        <h1 style="color:#fff">Sign in to SkyApp</h1>
        <p-button label="Sign in with WSSO" icon="pi pi-sign-in" (click)="loginWSSO()"></p-button>
        <!-- <p-button label="Sign in with AviationID" icon="pi pi-sign-in" (click)="loginAviationID()"></p-button> -->
    </div>    
</div>




