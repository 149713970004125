import { Component, OnInit } from '@angular/core';
import { LoadingService } from 'src/app/services/loading/loading.service';
import { SkyappJWTAuthService } from 'src/app/services/skyapp-jwt-auth/skyapp-jwtauth.service';
import { UtilService } from 'src/app/services/util/util.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  constructor(){
  
  }
  ngOnInit(): void {
   
  }

}
