import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FlightLeg } from 'src/app/models/flight-leg-interface';
import { FileUploadService } from 'src/app/services/file-upload/file-upload.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UploadFlightLegDialogComponent } from 'src/app/modules/home/components/upload-flight-leg-dialog/upload-flight-leg-dialog.component';
import { FileUploadContainer } from 'src/app/models/upload-file-interface';
import { FolderDownloadService } from 'src/app/services/file-download/folder-download.service';

@Component({
  selector: 'app-upload',
  templateUrl: './upload.component.html',
  styleUrls: ['./upload.component.scss'],
})
export class UploadComponent implements OnInit {
  @Input() flightLeg!: FlightLeg;   // selected Flight leg from parent component
  @Input() uploadType!: string;
  @Input() multiFileDetails!: FileUploadContainer[]; // File details from parent component
  @Output() fileChange = new EventEmitter<any>(); // use of triggered the function in parent component

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<UploadFlightLegDialogComponent>,
    public uploadService: FileUploadService,
    private folderDownloadService: FolderDownloadService,
  ) { }

  ngOnInit(): void {
    // This method is used to display the ongoing file upload progress and state while the user remains on the same page
    this.getFileStatusAndProgress();
  }

  async uploadFile(type: string) {
    let sasToken: any;

    if (type === 'Arbitrary-Upload') {
      let bloburl = `${this.flightLeg.bemsId}/${this.flightLeg.legGUID}/${'arbitrary-data'}/`;
      sasToken = await this.folderDownloadService
        .generateSasToken('UPLOAD', environment.extractedDataContainername, bloburl)
        .toPromise();
      sasToken = `?${sasToken.token}`;
    } else {
      let bloburl = `${this.flightLeg.bemsId}/${this.multiFileDetails[0].fileName}`;
      sasToken = await this.folderDownloadService
        .generateSasToken('UPLOAD', environment.rawDataContainerName, bloburl)
        .toPromise();
      sasToken = `?${sasToken.token}`;
    }

    this.multiFileDetails = this.multiFileDetails.filter(eachFileDetail => eachFileDetail.state !== 'Completed' && eachFileDetail.state !== 'In Progress');

    for (let index = 0; index < this.multiFileDetails.length; index++) {
      let eachFileDetail = this.multiFileDetails[index];
      try {
        this.uploadService.upload(eachFileDetail, this.flightLeg, sasToken, this.dialogRef, eachFileDetail.identifier, eachFileDetail.uploadType, index, this.multiFileDetails.length);
        this.multiFileDetails = this.multiFileDetails.filter(file => file.state !== 'Completed');
        this.fileChange.emit(this.multiFileDetails);
        
      } catch (error) {
        console.error(`Error uploading file ${eachFileDetail.fileName}:`, error);
        // Handle the error appropriately, maybe continue with the next file or stop the process
      }
    }

  }

  async cancelUpload(fileDetail: FileUploadContainer): Promise<void> {
    await this.uploadService.cancelUpload(fileDetail.identifier);
    //this.fileChange.emit(fileDetail);
  }

  resetStage(fileDetail: FileUploadContainer): void {
    this.uploadService.removeUploadStatus(fileDetail.identifier);
    this.fileChange.emit(fileDetail);
  }

  // This method is used to display the ongoing file upload progress and state while the user remains on the same page
  getFileStatusAndProgress() {
    this.uploadService.getFileProgressUpdates().subscribe((status) => {
      console.log("status", status);

      this.multiFileDetails.forEach((fileDetail) => {
        let identifier = fileDetail.identifier;
        if (status[identifier]) {
          fileDetail.progress = status[identifier].progress;
          fileDetail.state = status[identifier].state;

          console.log(`File ${fileDetail.fileName} is ${status[identifier].state} with progress ${status[identifier].progress}%`);
        }
      });
    });
  }
}
