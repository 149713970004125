<div style="height: 90%;">
    <div class="card" style="justify-content: space-between; align-items: center;">
        <p-tabMenu [model]="items" [activeItem]="activeItem" (activeItemChange)="onActiveItemChange($event)"
            class="border-shadow-outline-removed selectedTab-hightlight-and-textcolor-changed unselectedTab-text-color" />
        <mat-icon style="position: absolute; top: 17px; right: 10px; cursor: pointer;"
            (click)="onNoClick()">close</mat-icon>
    </div>
    <div style="padding: 10px; height: inherit;" *ngIf="activeItem.label === 'Upload FSP Data'">
        <div *ngIf="selectedFlightLeg.rawDataUploaded === false && fspDataFileArray.length === 0" style="display:grid; justify-content: center; height: inherit;">
            <div class="container-center">
                <img class="m-2" src="../../../../../assets/boeing/upload-file.png" />
                No files added/uploaded yet.
                <div class="m-2">
                    <input #fileInput hidden="true" type="file" onclick="this.value=null"
                        (change)="fileBrowseHandler($event, 'FSP-Upload')" accept=".zip" />
                    <button mat-flat-button class="upload-button" (click)="fileInput.click()">Browse for file</button>
                </div>
            </div>
        </div>
        <div>
            <div *ngIf="selectedFlightLeg.rawDataUploaded === true" style="color: #000000; font-weight: bold;">
                File Uploaded
            </div>
            <div *ngIf="fspDataFileArray.length > 0">
                <app-upload uploadType="FSPData" [flightLeg]="selectedFlightLeg" [multiFileDetails]="fspDataFileArray"
                (fileChange)="onfileChange($event)"></app-upload>
            </div>
            
        </div>
    </div>

    <div style="padding: 10px; height: inherit;" *ngIf="activeItem.label === 'Upload Arbitrary Data'">
        <div *ngIf="arbitraryDataFileArray && arbitraryDataFileArray.length === 0; else elseBlock"
            style="display:grid; justify-content: center; height: inherit;">
            <div class="container-center">
                <img class="m-2" src="../../../../../assets/boeing/upload-file.png" />
                No files added/uploaded yet.
                <div class="m-2">
                    <input #fileInput hidden="true" type="file" multiple onclick="this.value=null"
                        (change)="fileBrowseHandler($event, 'Arbitrary-Upload')" accept=".zip" />
                    <button mat-flat-button class="upload-button" (click)="fileInput.click()">Browse for
                        files</button>
                </div>
            </div>
        </div>
        <ng-template #elseBlock>
            <!-- <div *ngIf="arbitraryDataFileArray.length > 0" style="color: #000000; font-weight: bold;">
                {{ arbitraryDataFileArray.length }} file{{ arbitraryDataFileArray.length > 1 ? 's' : '' }} added
            </div> -->
            <app-upload uploadType="ArbitraryData" [flightLeg]="selectedFlightLeg" [multiFileDetails]="arbitraryDataFileArray"
                (fileChange)="onfileChange($event)"></app-upload>
        </ng-template>
        
    </div>

    <div class="dialog-footer" *ngIf="activeItem.label === 'Upload FSP Data'">
        <button class="mr-2 ml-2" mat-stroked-button (click)="onNoClick()">
            Cancel
        </button>
        <button *ngIf="selectedFlightLeg.rawDataUploaded === false" class="mr-2 ml-2" mat-stroked-button [ngClass]="disableButton ? 'disabled-button' : ''"
            [disabled]="disableButton" (click)="startupload('FSP-Upload')">
            Upload
        </button> 
    </div>

    <div class="dialog-footer-arbitrary" *ngIf="activeItem.label === 'Upload Arbitrary Data'">
        <input #multiFileInput hidden="true" type="file" multiple onclick="this.value=null"
            (change)="fileBrowseHandler($event, 'Arbitrary-Upload')" accept=".zip" />
        <div class="left-placeholder">
            <button class="button-add-more-files" *ngIf="arbitraryDataFileArray && arbitraryDataFileArray.length > 0"
            [disabled]="isUploading" (click)="multiFileInput.click()"><i class="pi pi-plus-circle"></i>&nbsp; Add more files</button>
        </div>
        <div class="right-buttons">
            <button class="mr-2 ml-2" mat-stroked-button [ngClass]="disableButton ? 'disabled-button' : ''"
                [disabled]="disableButton" (click)="startupload('Arbitrary-Upload')">
                Upload
            </button>
            <button class="mr-2 ml-2" mat-stroked-button (click)="onNoClick()">
                Cancel
            </button>
        </div>
    </div>
</div>