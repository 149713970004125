<!-- <div *ngIf="data.status" class="dialog-header" style="background-color: #82BC00;">
  <div >
    <div *ngIf="data.status" style="margin: 2px;"> </div>
  </div>
  <div>
    <mat-icon style="align-content: flex-end;" (click)="onNoClick()">close</mat-icon>
  </div>
</div>
   -->
<div class="dialog-header" [style.background-color]="data.status ? '#82BC00' : '#D04C4C'">
  <div *ngIf="data.status" style="display: flex; color: #263746">
    <mat-icon>done_all</mat-icon>
    Flight Leg -{{ data.flightleg.legGUID }} has been successfully uploaded.
  </div>
  <div *ngIf="!data.status" style="display: flex; color: #263746">
    <mat-icon>report</mat-icon>
    <div>
      Failed, an error occured while uploading the Flight leg File
      {{ data.flightleg.legGUID }}.<a (click)="Reopen()">
        Click to try again.</a>
    </div>
  </div>
  <mat-icon style="align-content: flex-end" (click)="onNoClick()">close</mat-icon>
</div>