import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { BlobDownloadResponseModel, BlobServiceClient, ContainerClient } from '@azure/storage-blob';
import * as saveAs from 'file-saver';
import * as JSZip from 'jszip';
import { Observable, Subject } from 'rxjs';
import { FileDownloadContainer } from 'src/app/models/file-download-container-interface';
import { FileToDownload } from 'src/app/models/file-to-download-interface';
import { FlightLeg } from 'src/app/models/flight-leg-interface';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FolderDownloadService {
  private blobServiceClient!: BlobServiceClient;
  private containerClient!: ContainerClient;

  download_files: { [key: string]: FileToDownload[] } = {};
  download_files_details: { [key: string]: FileDownloadContainer } = {};

  private downloadProgressSubject = new Subject<{ [key: string]: FileDownloadContainer }>(); // File's progress used in modal, MB of file complete.
  fileDownloadContainer!: FileDownloadContainer;


  constructor(private http: HttpClient) { }


  // async initializeBlobService(path: string): Promise<void> {
  //   this.generateSasToken('DOWNLOAD', environment.extractedDataContainername, path).subscribe(
  //     (response) => {
  //       this.blobServiceClient = new BlobServiceClient(`${environment.storageAccountBaseUrl}?${response.token}`);
  //       this.containerClient = this.blobServiceClient.getContainerClient(environment.extractedDataContainername);
  //       console.log(this.containerClient);

  //     }
  //   );
  // }
  
  generateSasToken(action:string,containerName:string,directoryPath: string): Observable<any> { // generate Sas Token used for upload and download.
    return this.http.get<any>(
      `${environment.baseUrl}/api/file-storage/generate-sas-token/${action}?containerName=${containerName}&directoryPath=${directoryPath}`
    );
  }

   downloadContent(fileName:string,path: string,  identifier:string, containerName: string) {

    this.generateSasToken('DOWNLOAD', containerName, path).subscribe(
     async (response) => {
        this.blobServiceClient = new BlobServiceClient(`${environment.storageAccountBaseUrl}?${response.token}`);
        this.containerClient = this.blobServiceClient.getContainerClient(containerName+"/");
        const zip = new JSZip();
        console.log("Path::",path);
        
        let filesConsolidated = await this.getFilesSummary(path);
        this.download_files[identifier] = filesConsolidated.files
        this.download_files_details[identifier] = {
          loadedBytes: 0,
          prevTransferredBytes:0,
          totalBytes: filesConsolidated.totalBytes,
          state: 'In Progress',
          name: fileName
        }
        this.downloadProgressSubject.next(this.download_files_details);
        if (path.endsWith('/')) {
          // If the path is a folder, download its content
          const filesAndFolders = await this.listFilesAndFolders(path);
          await this.downloadFolderContent(zip, filesAndFolders, path, identifier);
          // Wait for all downloads to complete before generating the zip file
          const zipBlob = await zip.generateAsync({
            type: 'blob',
          });
          saveAs(zipBlob, fileName);
        } else {
          // If the path is a file, download it directly
          await this.downloadFileDirectly(path, fileName, identifier);
        }
        
      }
    );

  
  }

  private async downloadFolderContent(
    zip: JSZip,
    filesAndFolders: any[],
    path: string,
    identifier: string
  ): Promise<void> {
    const downloadPromises = filesAndFolders.map(async (item) => {
      if (item.kind === 'blob') {
        let fileName = this.removeParentPathFromFileName(item.name, path);
        return this.downloadFile(zip, item.name, fileName, identifier);
      } else if (item.kind === 'prefix') {
        // Recursively download subfolder content
        const subfolderFilesAndFolders =
          await this.listFilesAndFolders(item.name);
        return this.downloadFolderContent(zip, subfolderFilesAndFolders, path, identifier);
      }
    });

    // Wait for all download promises to complete
    await Promise.all(downloadPromises);
  }

  private removeParentPathFromFileName(
    fileName: string,
    parentPath: string
  ): string | null {
    // Escape special characters in the parent path
    const escapedParentPath = parentPath.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');

    // Create a regular expression to match the parent path and any leading slash
    const regex = new RegExp(`^${escapedParentPath}/?`);

    // Remove the parent path and any leading slash from the file path
    const finalFileName = fileName.replace(regex, '');

    // Return the result
    return finalFileName || null;
  }

  private downloadFile(
    zip: JSZip,
    fileLocation: any, //uuid/extra
    fileName: any,
    identifier: string
  ): Promise<void> {
    let file_name: string;
    return new Promise(async (resolve) => {
      const blobResponse = await this.downloadBlob(
        fileLocation,
        (loadedBytes: number) => {
          file_name = this.getFileNameFromPath(fileName);
          const fileList = this.download_files[identifier];
          if (fileList) {
            const fileObj = fileList.find(
              (f) => f.name === fileLocation
            );
            if (fileObj) {
                fileObj.prevBytesTransferred = fileObj.loadedBytes;
                fileObj.loadedBytes = loadedBytes;
                this.fileDownloadContainer = this.download_files_details[identifier];
                if (this.fileDownloadContainer) {
                  this.fileDownloadContainer.loadedBytes = fileList.reduce((accumulator, file) => {return accumulator + file.loadedBytes;}, 0);
                  if((this.fileDownloadContainer.loadedBytes - this.fileDownloadContainer.prevTransferredBytes >=1048576) || (this.fileDownloadContainer.totalBytes - this.fileDownloadContainer.loadedBytes < 1048576)  ){
                    this.fileDownloadContainer.prevTransferredBytes = this.fileDownloadContainer.loadedBytes;
                    if (this.fileDownloadContainer.totalBytes == this.fileDownloadContainer.loadedBytes) {
                      this.fileDownloadContainer.state = 'Completed';
                    }
                    this.downloadProgressSubject.next(this.download_files_details);
                  }
                } 
            }
          }
        }
      );
      const blobContent = blobResponse.blobBody;
      zip.file(fileName, blobContent ? blobContent : '');
      // Increment progress for each processed file
      resolve();
    });
  }

  private getFileNameFromPath(filePath: string): string {
    // Use a regular expression to capture the file name at the end of the path
    const regex = /[^/]+$/;
    const match = filePath.match(regex);
    // Throw an error if no match is found
    if (!match) {
      throw new Error(`No file name found in the path: ${filePath}`);
    }
    // Return the matched file name or null if no match is found
    return match[0];
  }

  private async downloadFileDirectly(
    fileLocation: any,
    fileName: any,
    identifier: string
  ): Promise<void> {
    let file_name: string;
    const blobResponse = await this.downloadBlob(
      fileLocation,
      (loadedBytes: number) => {
        file_name = this.getFileNameFromPath(fileName);
        console.log('file_name' + file_name);
        console.log('Loaded Bytes---------------' + loadedBytes);

        // Update array_file_1 and array_file_2
        const fileList = this.download_files[identifier];

        if (fileList) {
          const fileObj = fileList.find(f => f.name === fileLocation);
          // console.log('fileObj-----------' + fileObj);
          if (fileObj) {
            fileObj.prevBytesTransferred = fileObj.loadedBytes;
            fileObj.loadedBytes = loadedBytes;
            this.fileDownloadContainer = this.download_files_details[identifier];
            if (this.fileDownloadContainer) {
              this.fileDownloadContainer.loadedBytes = fileList.reduce((accumulator, file) => {return accumulator + file.loadedBytes;}, 0);
              if((this.fileDownloadContainer.loadedBytes - this.fileDownloadContainer.prevTransferredBytes >=1048576) || (this.fileDownloadContainer.totalBytes - this.fileDownloadContainer.loadedBytes < 1048576)  ){
                this.fileDownloadContainer.prevTransferredBytes = this.fileDownloadContainer.loadedBytes;
                if (this.fileDownloadContainer.totalBytes == this.fileDownloadContainer.loadedBytes) {
                  this.fileDownloadContainer.state = 'Completed';
                }
                this.downloadProgressSubject.next(this.download_files_details);
              }
            } 
          }
        }
      }
    );
    const blobContent = await blobResponse.blobBody;
    if (blobContent != undefined) {
      // Directly download the file
      saveAs(blobContent, fileName);
    }
  }

  async getFilesSummary(prefix: string = ''): Promise<any> {
    const blobs = this.containerClient.listBlobsFlat({ prefix });

    let filesConsolidated: any = {};
    let files: FileToDownload[] = [];
    let totalBytes: number = 0;
    for await (const blob of blobs) {
      let temp: any = blob;
      if (temp.properties.ResourceType == 'file') {
        totalBytes += temp.properties.contentLength;
        files.push({ name: temp.name, loadedBytes: 0, prevBytesTransferred: 0, fileTotalBytes: temp.properties.contentLength });
      }
    }
    filesConsolidated.totalBytes = totalBytes;
    filesConsolidated.files = files;

    console.log("FilesConsolidated", filesConsolidated);

    return filesConsolidated;
  }

  async listFilesAndFolders(prefix: string = ''): Promise<any[]> {
    const blobs = this.containerClient.listBlobsByHierarchy("/", { prefix: prefix });
    const result: any[] = [];

    for await (const blob of blobs) {
      result.push(blob);
    }
    return result;
  }

  async downloadBlob(blobPath?: string, progressCallback?: (loadedBytes: number) => void): Promise<BlobDownloadResponseModel> {
    const blobClient = this.containerClient.getBlobClient(`${blobPath}`);

    // Get blob size for progress tracking
    const properties = await blobClient.getProperties();
    const totalBytes = properties.contentLength;

    return await blobClient.download(0, undefined, {
      onProgress: (ev) => {
        const loadedBytes = ev.loadedBytes;
        if (progressCallback) {
          progressCallback(loadedBytes);
        }
      },
    });
  }

  getFileProgressUpdates(): Subject<{ [key: string]: FileDownloadContainer }> {
    return this.downloadProgressSubject;
  }

  getFileStatusAndProgress() {
    // this.getFileProgressUpdates().subscribe((status) => {
    //   if (status[this.selectedFlightLeg.legGUID + '-' + this.fileDownloadContainer.identifier]) {
    //     this.fileDownloadContainer.progress = status[this.selectedFlightLeg.legGUID + '-' + this.fileDownloadContainer.identifier].progress;
    //     this.fileDownloadContainer.totalBytes = status[this.selectedFlightLeg.legGUID + '-' + this.fileDownloadContainer.identifier].totalBytes;
    //     console.log('name -' + this.fileDownloadContainer.name);
    //     console.log('Progress -' + this.fileDownloadContainer.progress);
    //     //this.fileDetail.status = status[this.flightLeg.legGUID + '-' + this.fileDetail.uploadIdentifier+'-'+this.fileDetail.name].state;
    //   }
    // });
  }
}
