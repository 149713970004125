import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { HomeComponent } from './components/home/home.component';
import { Button, ButtonModule } from 'primeng/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { FileUploadModule } from 'primeng/fileupload';
import { PaginatorModule } from 'primeng/paginator';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { TableModule } from 'primeng/table';
import { TooltipModule } from 'primeng/tooltip';
import { LocalDateTimePipe } from 'src/app/shared/pipes/local-date-time-pipe';
import { TimeFormatPipe } from 'src/app/shared/pipes/time-format.pipe';
import { SharedModule } from 'src/app/shared/shared.module';
import { ErrorModule } from '../error/error.module';
import { DetailsDialogComponent } from './components/details-dialog/details-dialog.component';
import { FlightLegListComponent } from './components/flight-leg-list/flight-leg-list.component';
import { UploadFlightLegDialogComponent } from './components/upload-flight-leg-dialog/upload-flight-leg-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { TabMenuModule } from 'primeng/tabmenu';


@NgModule({
  declarations: [
    HomeComponent,
    FlightLegListComponent,
    DetailsDialogComponent,
    UploadFlightLegDialogComponent
  ],
  imports: [
    CommonModule,
    FileUploadModule,
    SharedModule,
    TableModule,
    PaginatorModule,
    MatInputModule,
    MatGridListModule,
    MatExpansionModule,
    MatIconModule,
    MatTooltipModule,
    MatDialogModule,
    TooltipModule,
    ErrorModule,
    TabMenuModule,
    ProgressSpinnerModule,
    ButtonModule,
    MatButtonModule
  ],
  providers:[
    DatePipe,
    LocalDateTimePipe,
    TimeFormatPipe
  ],
  exports: [
    HomeComponent,
  ]
})
export class HomeModule { }
