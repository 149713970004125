import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter, Observable } from 'rxjs';
import { wssoAuthCodeFlowConfig } from 'src/app/auth.config';
import { SkyappJWTAuthService } from 'src/app/services/skyapp-jwt-auth/skyapp-jwtauth.service';

@Injectable({
  providedIn: 'root',
})
export class SkyappJWTAuthGuard implements CanActivate {
  constructor(
    private skyappJwtAuthSerice: SkyappJWTAuthService,
    private router: Router,
    private oauthService: OAuthService
  ) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {

      let isTokenValid = this.skyappJwtAuthSerice.areAllTokenValid();
      console.log('Auth Guard Called: isTOkenValid', isTokenValid);
      if (isTokenValid) {
        return true;
      } else {
        if (state.url.includes('/home') && state.url.includes('error=')) {
          const params = new URLSearchParams(state.url.split('?')[1]); // Splitting to get query params
          const error = params.get('error');
          if(error){
            alert('Login Failed: Redirected with error: ' + error);
          }
        } else {
          this.skyappJwtAuthSerice.authenticate();
        }
        return true;
      }
      
  

   
    // this.skyappJwtAuthSerice.setAccesTokenValidity(true);
    // let isTokenValid = this.skyappJwtAuthSerice.hasValidSkyAppToken();
    //   console.log('Auth Guard Called: isTOkenValid', isTokenValid);
    //   if (isTokenValid) {
    //     this.skyappJwtAuthSerice.setSkyAppTokenValidity(true);
    //     return true;
    //   } else {
    //     this.skyappJwtAuthSerice.validateUserAndGenerateJWT("3534002").subscribe(data => {
    //       if(data.token){
    //         this.skyappJwtAuthSerice.setSkyAppTokenValidity(true);
    //         this.router.navigate(['/home']);
    //       }
    //   });      
    //   }
    //   return true;
  }
}
