import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TimeFormatService {
  private formatSubject = new BehaviorSubject<'utc' | 'local'>('local');
  currentFormat$ = this.formatSubject.asObservable();

  setFormat(format: 'utc' | 'local') {
    this.formatSubject.next(format);
  }

}