import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { OAuthService } from 'angular-oauth2-oidc';
import { filter } from 'rxjs';
import { wssoAuthCodeFlowConfig } from 'src/app/auth.config';
import { SkyappJWTAuthService } from 'src/app/services/skyapp-jwt-auth/skyapp-jwtauth.service';
import { UserService } from 'src/app/services/user/user.service';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.scss'],
})
export class LoginViewComponent implements OnInit {

  isRedirected = false;
  constructor(
    private oauthService: OAuthService,
    private skyappJwtAuthSerice: SkyappJWTAuthService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    // //redirect url of our app with idp name as query parameter
    // let url = new URL(window.location.href);
    // console.log(url);

    // console.log('IDP name from url:', url.searchParams.get('idp'));
    // // console.log('IDP name from localstorage:', this.skyappJwtAuthSerice.getIDPFromStorage());



    // let idp_name ;
    // if(url.searchParams.get('idp')){
    //   idp_name = url.searchParams.get('idp');
    //   this.isRedirected=true;
    // }
    // else{
    //   // idp_name = this.skyappJwtAuthSerice.getIDPFromStorage();
    // }

    // console.log('IDP:', idp_name);

    // console.log('initializing oauth2 configuration...');
    
    // if (idp_name == 'wsso') {
    //   this.oauthService.configure(wssoAuthCodeFlowConfig);
    //   // this.skyappJwtAuthSerice.setIDPtoStorage(idp_name);

    // } else if (idp_name == 'aviationid') {
    //   this.oauthService.configure(aviatioIdAuthCodeFlowConfig);
    //   // this.skyappJwtAuthSerice.setIDPtoStorage(idp_name);
    // } else {
    //   this.oauthService.configure(aviatioIdAuthCodeFlowConfig);
    // }
    // console.log('enable automatic silent refresh...');
    // this.oauthService.setupAutomaticSilentRefresh();
    // console.log('check session storage for tokens and for oauth2 call back...');
    // this.oauthService.loadDiscoveryDocumentAndTryLogin().then(() => {
    //   // Check if we have valid tokens from fedProxy server
    //   if (
    //     this.oauthService.hasValidAccessToken() &&
    //     this.oauthService.hasValidIdToken()
    //   ) {
        
    //     console.log('User authenticated by fedProxy...');
    //     // Check if We have valid Skyapp token
    //     if (this.skyappJwtAuthSerice.hasValidSkyAppToken()) {
    //       // if we have all valid tokens then publish that we have valid token to observers of tokenValidityObserver()
    //       console.log('Has valid skyapp token');
    //       this.skyappJwtAuthSerice.setAndPublishTokenValidity(true);
    //       this.router.navigate(['/home']);
    //     }
    //     // if the skyapp token is not valid then initiate a call to get new skyapp token only when
    //     // user is redirected from fedproxy and not on page refresh. while redirecting there will a
    //     // search entity in  url holding the code, which will not be present in page refresh
    //     // we dont want user to automatically generate new skyapp token when user logs our or refresh the login page but
    //     // it should happend automatically when a page redirect from fedproxy happens
    //     else {
    //       // else if(url.search.length>0){
    //       console.log('Call validateUser to get new skyapp token');
    //       let bemsId = this.skyappJwtAuthSerice.getBemsId();
    //       console.log(bemsId);
    //       this.skyappJwtAuthSerice.validateUserAndGenerateJWT(bemsId).subscribe(
    //         (tokenResponse) => {
    //           this.skyappJwtAuthSerice.setAndPublishTokenValidity(true);
    //           this.router.navigate(['/home']);
    //         },
    //         (error) => {
    //           this.skyappJwtAuthSerice.setAndPublishTokenValidity(false);
    //           console.error(error);
    //           alert('Login Failed');
    //         }
    //       );
    //     }
    //   } else {
    //     // this.skyappJwtAuthSerice.setAndPublishTokenValidity(false);
    //     console.log('User not authenticated... try silent refresh...');
    //     this.oauthService.silentRefresh().then((response) => {
    //       console.log('silentRefresh response: ' + JSON.stringify(response));
    //       console.log('Call validateUser to get new skyapp token');
    //       let bemsId = this.skyappJwtAuthSerice.getBemsId();
    //       console.log(bemsId);
    //       this.skyappJwtAuthSerice.validateUserAndGenerateJWT(bemsId).subscribe(
    //         (tokenResponse) => {
    //           this.skyappJwtAuthSerice.setAndPublishTokenValidity(true);
    //           this.router.navigate(['/home']);
    //         },
    //         (error) => {
    //           this.skyappJwtAuthSerice.setAndPublishTokenValidity(false);
    //           console.error(error);
    //           alert('Login Failed');
    //         }
    //       );
    //     });
    //   }
    // });

    // // Automatically load user profile
    // // this.oauthService.events
    // //   .pipe(filter((e) => e.type === 'token_received'))
    // //   .subscribe((_) => this.oauthService.loadUserProfile());

    // this.oauthService.events
    //   .pipe(filter((e) => e.type == 'silent_refresh_error'))
    //   .subscribe((_) => {
    //     if (this.oauthService.getAccessToken()) {
    //       // if we got a silent refresh error and we have an access token, then we need to warn the user
    //       console.log('Need page redirect to authenticate...');
    //       console.log(Date.now());

    //       var confirmed = confirm(
    //         'Credentials stored in this browser are no longer good and silent refresh has failed. Click OK to re-authenticate...'
    //       );
    //       if (confirmed) {
    //         this.oauthService.initCodeFlow();
    //       }
    //     }
    //   });
  }

  loginWSSO() {
    console.log('initializing oauth2 configuration for WSSO...');
    this.oauthService.configure(wssoAuthCodeFlowConfig);
    this.oauthService.setupAutomaticSilentRefresh();
    console.log('check session storage for tokens and for oauth2 call back...');
    this.oauthService.loadDiscoveryDocumentAndTryLogin().then((response) => {
      if (response){
        this.oauthService.initLoginFlow();
      }
    });
  }

  // loginAviationID() {
  //   console.log('initializing oauth2 configuration for AviationID...');
  //   this.oauthService.configure(aviatioIdAuthCodeFlowConfig);
  //   this.oauthService.setupAutomaticSilentRefresh();
  //   console.log('check session storage for tokens and for oauth2 call back...');
  //   this.oauthService.loadDiscoveryDocumentAndTryLogin().then((response) => {
  //     if (response){
  //       this.oauthService.initLoginFlow();
  //     }
  //   });
  // }
}
